import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import AllInOne from '../../../../../components/case-studies/AllInOne'
import MobileGallery from '../../../../../components/case-studies/MobileGallery'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Reveal from '../../../../../components/reveal'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba portálu Lokall'}
          description={"Prípadová štúdia"}

          description2={"Moderný štýlový portál s eshopom, ktorý spája kupujúcich a lokálnych výrobcov oblečenia, kozmetiky či potravín."}
          text={'Nový koncept webovej platformy na mieru poskytuje zákazníkom množstvo výhod. Našou misiou bolo vytvoriť technicky pokročilú web aplikáciu v prívetivom a používateľsky nenáročnom prevedení.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'UX & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' }
          ]} />

        <AboutProject
          title={'Profesionálny web nevznikne sám od seba'}
          text={'Tvorba web stránok na najvyššej úrovni si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: '<a class="blue-link" href="/tvorba-webov">Webová stránka</a> spájajúca eshop a portál v jednom, fungujúca ako platforma pre lokálnych výrobcov. Tí môžu ponúkať svoje handmade produkty a zároveň uľahčiť zákazníkom ich hľadanie. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Cieľ bol navrhnúť túto webovú stránku na mieru tak, aby sa v nej dokázali návštevníci veľmi rýchlo zorientovať. Vďaka intuitívnemu <a class="blue-link" href="/webdizajn">dizajnu webu</a> musí byť už na prvý klik zrejmé ako funguje, a zároveň mať najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Pri projektoch sa často navrhuje aj <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby.</b>',
            }
          ]}
        />

        <AllInOne
          fixedTitleWidth={true}
          title={'Webový dizajn na <br/> mieru'}
          description={'Profesionálny portál sa bez neho nezaobíde.'}
          text={'Svieži dizajn portálu Lokall obsahuje všetko, čo by mala moderná webstránka spĺňať. Nielen jedinečnosť a estetickosť, ale aj responzivita, intuitívne používateľské rozhranie, prehľadnosť a funkčnosť sú atribúty, ktoré návštevník očakáva od portálu, do ktorého sa bude pravidelne vracať.'}
          projectDetail={'/nase-prace/webstranky/portaly/lokall'}//TODO
          images={data.webImages}
        />

        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="lokall" />
          </div>
        </Reveal>

        <MobileGallery
          className="brown"
          title={'Mobilná verzia'}
          description={'Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'}
          text={"Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='blue-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný portál a chce, aby mu prinášal čo najväčší zisk."}
          projectDetail={'/nase-prace/webstranky/eshopy/primadonna-collection'}//TODO
          images={data.mobileGallery}
        />


        {/*  <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        /> */}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.lokall.sk" target='_blank' className='link-secondary'>www.lokall.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Zamestnam'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/portaly/zamestnam/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/lokall/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/lokall/visual.png" }) {
      childImageSharp {
          fluid( maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
    webImages: allFile(filter: { relativePath: {regex: "/(case-studies/lokall/webGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/lokall/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
